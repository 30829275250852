import React from 'react';
import './Clients.css';

import Pfizer from '../images/clients/Pfizer.png';
import AstraZeneca from '../images/clients/astrazeneca-logo.png';
import Teva from '../images/clients/TevaPharm.png';
import CSLBearing from '../images/clients/csl-behring-logo-ECA3942644-seeklogo.com.png';
import Ablynx from '../images/clients/ablynx-logo.png';
import Porton  from '../images/clients/logo-8-300x86.jpg';


const Clients = () => {
  return (
    <div className='client-section section-vertical-padding' id='clients'>
      <div className="client-info">
        <h4 className='text-center'> Our Clients</h4>
      </div>
        <div class="logos">
            <div class="logos-slide">
                <img src={Pfizer} alt='tech'/>
                <img src={AstraZeneca} alt='tech' />
                <img src={Teva} alt='tech' />
                <img src={CSLBearing} alt='tech' />
                <img src={Ablynx} alt='tech' />
                <img src={Porton} alt='tech' />
            </div>

            <div class="logos-slide">
            <img src={Pfizer} alt='tech'/>
                <img src={AstraZeneca} alt='tech' />
                <img src={Teva} alt='tech' />
                <img src={CSLBearing} alt='tech' />
                <img src={Ablynx} alt='tech' />
                <img src={Porton} alt='tech' />
            </div>
        </div>
    </div>
)

}

export default Clients