import React from 'react'
import aboutImage from '../images/qlife.jpeg';

const Qlife = () => {
  return (
    <>
      <div className='about-section m-5 p-5'>
        <div className="container">
        <h4 className='text-center'>Our Partners</h4>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="about-image">
                <img className='img-fluid about-image' src={aboutImage} alt='' />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div class="align-self-center pt-2">
                <div className='about-text'>
                <h2 className='py-2'>We are now offically partnered with QLIFE for better life</h2>


                  <h2 className='py-4'>INNOVATIVE PHARMA BUSINESS SOLUTIONS</h2>

                    <p> QLIFE SCIENTIFIC OFFICE is a leading pharmaceutical marketing organization with strategic experience that plans to enter the Middle Eastern pharmaceutical sector.
                    </p>

                    <p></p>
                    <div className='pt-4'>
                      <button to='/about' primary='true' className='btn-new' css={`max-width: 160px;`}>Know More</button>
                    </div>                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Qlife