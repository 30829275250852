import React from 'react';
import './Header.css';
import faLogo from '../images/FA-logo.png';
// import { NavLink as Link } from 'react-router-dom';
// import styled from 'styled-components'
// import { NavLink as Link } from 'react-router-dom';
// import { NavLink as Link } from 'react-router-dom';

// const NavRouteItem = styled(Link)``;

function Header() {
  return (
    <header className="header">
      <div className="logo">
      {/* <Link to="/" className='btn-new'>know More</Link> */}
      <a href='/' className='header-logo'>
      {/* FA Pharma Consulting */}
      <img src={faLogo} alt='fa-logo' height={60} />
      </a>
      {/* <NavRouteItem>Hi</NavRouteItem> */}
        {/* <Link to="/">Fg</Link> */}
        </div>
      <nav>
        <ul>
          <li><a href="#about">About Us</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href='/careers' to='careers'>Careers</a></li>
          <li><a href="#clients">Clients & Partners</a></li>
          <li><a href="#contact">Contact Us</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
