import React from 'react';
import './AboutUs.css';
import abtImg from '../images/Homepage2.jpg';

const AboutUs = () => {
  return (
    <div className="about" id='about'>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="about-content">
              <h2 className="about-title">About FA Pharma</h2>
              <ul><li>
              <p className="about-text">
              FA Pharma consulting has over 20 years of experience in Regulatory Post Approval and Initial Marketing Authorization Submissions (MAA/BLA), Chemistry Manufacture and Control (CMC) and Global Regulatory Affairs for commercial and developing products, Biological Products, Rase diseases, Vaccines, Large Molecules and Small molecules.
              </p></li>
              <li>
              <p className="about-text">
              Extensive experience in the writing, reviewing Module 3 and Module 2.3 (QOS) for several commercial and developed products also have experience in pharmaceutical development, manufacturing, analytical development, quality control and quality assurance. 
              </p></li>
              <li>
              <p className="about-text">
              FA Pharma is one of the leading pharmaceutical consulting companies providing pharmaceutical regulatory affairs services to small, medium and large pharmaceutical industries and working throughout the pharmaceutical development, submission, approval and post approval product lifecycle. 
              </p></li>
              <li>
              <p className="about-text">
              Our tailored solutions help our clients to obtain regulatory approvals fast from health authorities so that the medicinal products available to patients. 
              </p></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="about-image">
              <img className='img-fluid' src={abtImg} alt="Pharmaceutical Products" /> {/* Replace with your image URL */}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
