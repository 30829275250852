import React from 'react'
// import styled from 'styled-components';
import './Services.css';
// import { NavLink as Link } from 'react-router-dom';
import ServiceLine from './ServiceLine';
// import Aos from "aos";
// import "aos/dist/aos.css";

const Services = () => {


  // useEffect(() => {
  //     Aos.init({duration: 2000});
  //   }, []);

  return (
    <div>
      {/* <Link to="/" className='btn-new'>Home</Link> */}
      <div className='service-section'>
        <div className="container">
          <h2 className='pb-5 text-center'><u>We provide SERVICES like,</u></h2>
          
          <p className='heading-text'>Regulatory Services:</p>
          <p className='heading-text'>We help pharmaceutical companies to register the products and gain regulatory approvals worldwide. We help with the following submissions.</p>
        </div>


        <ServiceLine />

      </div>
    </div>
  )
}

export default Services