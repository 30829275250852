import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './CareersForm.css';
// import { NavLink as Link } from 'react-router-dom';

const CareersForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    position: '',
    coverLetter: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!formData.firstName) validationErrors.firstName = 'First name is required';
    if (!formData.lastName) validationErrors.lastName = 'Last name is required';
    if (!formData.email) validationErrors.email = 'Email is required';
    if (!formData.phone) validationErrors.phone = 'Phone number is required';
    if (!formData.position) validationErrors.position = 'Position is required';

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    emailjs
      .sendForm('service_7g65xgv', 'template_dhw23pv', form.current, 'hWMJVC_Y_wsqaO1NT')
      .then(
        () => {
          alert('Application submitted successfully!');
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            position: '',
            coverLetter: '',
          });
        },
        (error) => {
          alert(`Failed to send application: ${error.text}`);
        }
      );
  };

  return (
    <div className="careers-form-container">
      {/* <Link to="/" className="btn-new">Home</Link> */}
      <h2>Join Our Team</h2>
      <p>Apply for a position at our pharmaceutical company by filling out the form below.</p>
      <form ref={form} onSubmit={handleSubmit} className="careers-form">
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
          />
          {errors.firstName && <span className="error">{errors.firstName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
          />
          {errors.lastName && <span className="error">{errors.lastName}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            id="phone"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="position">Position Applying For</label>
          <input
            id="position"
            type="text"
            name="position"
            value={formData.position}
            onChange={handleChange}
            required
          />
          {errors.position && <span className="error">{errors.position}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="coverLetter">Cover Letter (Optional)</label>
          <textarea
            id="coverLetter"
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleChange}
          ></textarea>
        </div>

        <button type="submit" className="submit-button">Submit Application</button>
      </form>
    </div>
  );
};

export default CareersForm;
