import React from 'react';
import './Hero.css';
import HeroLogo from '../images/FA-logo.png';

const Hero = () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h1 className="hero-title">
          {/* FA Pharma */}
          <img src={HeroLogo} alt='HeroLogo' height={150} />
        </h1>
        <p className="hero-subtitle">Innovating Health for a Better Tomorrow</p>
        <button className="hero-button">Learn More</button>
      </div>
    </div>
  );
};

export default Hero;